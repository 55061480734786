import * as React from 'react'
import Pic1 from "../components/348.png";
import Pic2 from "../components/578.png";
import Pic3 from "../components/959.png";
import { aboutPic,aboutContent,textBubble,aboutBubble } from '../components/layout.module.css'

const TopBanner = {
    // color: "#8A6534",
    padding: 4,
    // backgroundColor: "#FFF4DB",
    fontSize: "4rem",
    borderRadius: 4,
    "text-align": "center",
  }

const AboutList = {
    about: "About",
    aboutContent: ["MoonSenators is an NFT collection of 10,000 unique-and-program-generated creatures living in AVAX.",
    "They are immortal and lovely ancient creatures born before human existed.",
    "They find AVAX blockchain and integrated their existence into the code.",
    "When someone mint them out, they give out AVAX automatically.",
    "Last one to mint will receive a jackpot (value can be up to 1380 AVAX)",]
}

const bubbleList = {
    title : ["Mint Pass","Earning","Jackpot","Dao","Future Airdrop"],
    content:["Each collectible from MoonSenator will be a VIP mint pass for future collection ",
    "Each MoonSenator means a pass for earning fund from the contract. Every mint will reward one random holder 0.12 AVAX. The earlier you mint, the higher chance you receive AVAX. ",
    "The last one who mint the MoonSenator will gain up to 1380 AVAX, depending on the mint/burn ratio ",
    "Future of MoonSenators will be decided by holder of MoonSenator, one means one vote. ",
    "Holder of MoonSenators will receive our physical product in the future, to signify your NFT."],
}

const MoonSenator = () => {
    return (
        <div id="MoonSenatorContent">
            <h1 style={TopBanner}><span></span></h1>
            <div className={aboutContent}>
                <h1>{AboutList.about}</h1>
                <div className={aboutPic}>
                    <img src={Pic1} alt="The moon senator icon Pic1" width="250" height="250"/>
                    <img src={Pic2} alt="The moon senator icon Pic1" width="250" height="250"/>
                    <img src={Pic3} alt="The moon senator icon Pic1" width="250" height="250"/>
                </div>
                {AboutList.aboutContent.map(line => (
                    <p>{line} <br /></p>
                ))}
                <div className={aboutBubble}>
                    {bubbleList.title.map((title,index) => {
                        return(
                            <div className={textBubble}>
                                <h2>{title}</h2>
                                <p>{bubbleList.content[index]}</p>
                            </div>
                        )
                    })} 
                </div>
            </div>
        </div>
    )
}

export default MoonSenator