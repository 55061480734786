import * as React from 'react'
import { roadMapPic} from '../components/layout.module.css'
import Pic1 from "../components/1001.png";
import Pic2 from "../components/1192.png";
import Pic3 from "../components/1715.png";

const RoadMap = {
    title: "Roadmap ...?",
    content:["No bullshit.",
    "No roadmap.",
    "No empty promise.",
    "Just work our butt out. Let’s play the minting process first.",
    "I am just being honest anyway, not like other project that rugs people.",],
}

const MoonSenatorRoadMap = () => {
    return (
        <div id="MoonSenatorRoadMap">
            <div className={roadMapPic}>
                <img src={Pic1} alt="The moon senator icon Pic1" width="250" height="250"/>
                <img src={Pic2} alt="The moon senator icon Pic2" width="250" height="250"/>
                <img src={Pic3} alt="The moon senator icon Pic3" width="250" height="250"/>
            </div>
            <h1>{RoadMap.title}</h1>
            <br/>
            <h1>{RoadMap.content[0]}</h1>
            <h1>{RoadMap.content[1]}</h1>
            <h1>{RoadMap.content[2]}</h1>
            <h1>{RoadMap.content[3]}</h1>
            <h1>{RoadMap.content[4]}</h1>
        </div>
    )
}

export default MoonSenatorRoadMap