import * as React from 'react'
import {mintBox,pageStyles,sectionTopBackground,sectionFooter,sectionContent,sectionMint,sectionTeam,sectionNavBar,sectionFAQ,sectionWhat,sectionRoadMap,sectionMechanism} from '../components/layout.module.css'

const SectionBox = ({ id, sectionName, children }) => {
    let sectionChildStyle = pageStyles
    let sectionBoxStyle = sectionFooter;
    if(sectionName === "sectionTopBackground"){
        sectionBoxStyle = sectionTopBackground;
    }else if(sectionName === "sectionContent"){
        sectionBoxStyle = sectionContent;
    }else if(sectionName === "sectionTeam"){
        sectionBoxStyle = sectionTeam;
    }else if (sectionName === "sectionNavBar"){
        sectionBoxStyle = sectionNavBar;
        sectionChildStyle = "";
    }else if (sectionName === "sectionFAQ"){
        sectionBoxStyle = sectionFAQ;
    }else if (sectionName === "sectionMint"){
        sectionBoxStyle = sectionMint;
        sectionChildStyle = mintBox;
    }else if (sectionName === "sectionWhat"){
        sectionBoxStyle = sectionWhat;
        sectionChildStyle = "";
    }else if (sectionName === "sectionRoadMap"){
        sectionBoxStyle = sectionRoadMap;
    }else if (sectionName === "sectionMechanism"){
        sectionBoxStyle = sectionMechanism;
    }

    return (
        <div id={id} className={sectionBoxStyle}>
            <div className={sectionChildStyle}>
                {children}
            </div>
        </div>
    )
}

export default SectionBox