import * as React from 'react'
import { explainIMG } from '../components/layout.module.css'
// import Pharaoh from "../components/moonsenator_pharaoh.png";
import explainPNG from "../components/explain_small.png"

const MechanismList = {
    title: "Mint & Burn Mechanism",
    mintSummary:"Mint: Get one MoonSenators (MS) and split 0.12 AVAX to current MS holders or into the contract.",
    mintContent:["Good: Get one MS and be able to get 0.12 AVAX from later MS holder",],
    burnSummary:"Burn: Burn one MS, get one new MS. Split 0.008 AVAX to the contract.",
    burnContent:["Good: Decrease supply","Bad: Decrease the overall value of the jackpot"],
    lastMint:"Last Mint: the last one who minted the MS will get 10% of the contract value. The value depends on the burn and mint ratio",
}

const MoonSenatorMechanism = () => {
    return (
        <div id="MoonSenatorMechanism">
            <h1>{MechanismList.title}</h1>
            <img src={explainPNG} alt="The mechanism brief explanation" className={explainIMG} height="50" width="80"/>
            <p>
                <h2>Mint:</h2>
                <ul>mint one MoonSenators (MS) and send 0.12 AVAX to current MS holder or into the contract(if burn token ID). </ul>
            </p>
            <p>
                <h2>Burn:</h2>
                <ul>Burn one MS, get one new MS. Reduce supply. Split 0.008 AVAX to the contract. </ul>
            </p>
            <p>
                <h2>Mint:</h2>
                <ul>The last one who minted the MS will get 10% of the contract value. The value depends on the burn and mint ratio. </ul>
            </p>
            <p>
                <h2>Mint/Burn ratio:</h2>
                <ul>The Mint/Burn ratio affect the jackpot money.  </ul>
                <ul>↑Burn  = ↑ chance to get money in jackpot  + ↓amount of AVAX in jackpot </ul>
                <ul>↑Mint  = ↑ chance to get money individually  + ↑amount of AVAX in jackpot </ul>
            </p>
            {/* <p>
                <h3>{MechanismList.burnSummary}</h3>
                {MechanismList.burnContent.map(line => (
                    <ul>{line}</ul>
                ))}
            </p> */}
            <p>
                <h2>Kind of a game mechanism</h2>
                <p>To burn or to mint. It is your decision to decide. I personally expect 50% will be burnt.</p>
            </p>        
        </div>
    )
}

export default MoonSenatorMechanism