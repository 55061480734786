// extracted by mini-css-extract-plugin
export var ColorReference = "layout-module--ColorReference--R8F6Q";
export var pageStyles = "layout-module--pageStyles--YHHv8";
export var walletBox = "layout-module--walletBox--dbmYX";
export var metaMaskSVG = "layout-module--metaMaskSVG--gRLR+";
export var metaMaskSVGGrayIcon = "layout-module--metaMaskSVGGrayIcon--MwtCy";
export var sectionTopBackground = "layout-module--sectionTopBackground--+TK0V";
export var sectionContent = "layout-module--sectionContent--BOXJd";
export var sectionMint = "layout-module--sectionMint--15G1U";
export var mintBox = "layout-module--mintBox--nPuzt";
export var sectionFAQ = "layout-module--sectionFAQ--8O75V";
export var sectionFooter = "layout-module--sectionFooter--3CrlO";
export var sectionTeam = "layout-module--sectionTeam--2fe-w";
export var sectionWhat = "layout-module--sectionWhat--dDiGZ";
export var sectionRoadMap = "layout-module--sectionRoadMap--zfHOt";
export var sectionMechanism = "layout-module--sectionMechanism--RiWV1";
export var sectionNavBar = "layout-module--sectionNavBar--st+ks";
export var enableEthereumButton = "layout-module--enableEthereumButton--NUi1e";
export var showAccount = "layout-module--showAccount--jGRmb";
export var moonSenatorRoadMapSpan = "layout-module--moonSenatorRoadMapSpan--AsJyT";
export var moonSenatorRoadMap = "layout-module--moonSenatorRoadMap--yoVHR";
export var moonSenatorFAQ = "layout-module--moonSenatorFAQ--Rfewh";
export var animatedFAQ = "layout-module--animatedFAQ--rx38x";
export var NavigationButton = "layout-module--NavigationButton--qGSnH";
export var NavBarTitle = "layout-module--NavBarTitle--5a8mN";
export var TopNavWrap = "layout-module--TopNavWrap--ZoyLx";
export var NavBarIcon = "layout-module--NavBarIcon--NxHNy";
export var NavMenuItem = "layout-module--NavMenuItem--vo1XQ";
export var menu = "layout-module--menu--P1Nbr";
export var menuOpen = "layout-module--menuOpen--6tfZt";
export var moonSenatorTeam = "layout-module--moonSenatorTeam--fHtQi";
export var clickMeMsg = "layout-module--clickMeMsg--pgwaY";
export var tokenIDh2Style = "layout-module--tokenIDh2Style--BRSrP";
export var tokenIDInputStyle = "layout-module--tokenIDInputStyle--o4v++";
export var explainIMG = "layout-module--explainIMG--kMuW6";
export var whatBanner = "layout-module--whatBanner--ebftb";
export var whatContent = "layout-module--whatContent--s6lYu";
export var kennyProPic = "layout-module--kennyProPic--wYSUr";
export var aboutContent = "layout-module--aboutContent--Tqay+";
export var aboutPic = "layout-module--aboutPic--zCTS5";
export var aboutBubble = "layout-module--aboutBubble--IMTg9";
export var textBubble = "layout-module--textBubble--Vb9nI";
export var roadMapPic = "layout-module--roadMapPic--aEmlc";
export var footerIcon = "layout-module--footerIcon--dQNqi";
export var footerCopyRight = "layout-module--footerCopyRight--giUzQ";
export var nftMarquee = "layout-module--nftMarquee--D-4uO";
export var runFirst = "layout-module--runFirst--1Guvj";
export var delayed = "layout-module--delayed--9K8ci";
export var notConnectedBanner = "layout-module--notConnectedBanner---FZuQ";
export var footerIconDiv = "layout-module--footerIconDiv--G2GXH";
export var nftMarquee2 = "layout-module--nftMarquee2--CQ05-";