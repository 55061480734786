import * as React from 'react'
import { moonSenatorTeam,aboutBubble,textBubble } from '../components/layout.module.css'
import Pharaoh from "../components/moonsenator_pharaoh.png";
import kennyPNG from "../components/Kenny.png";
import gingerPNG from "../components/Ginger.png";
import edrikJPG from "../components/Edrik.jpg"; 

const TeamStory = {
    title: "Team Story",
    about_team:"Here are all our members. Ginger, Kenny and Edrik.",
    member:["Ginger","Kenny", "Edrik"],
    member_pic:[gingerPNG, kennyPNG, edrikJPG],
    member_bio:["Ginger, our Art Lead and Contract Author",
    "Kenny is just an IT noob. He barely knew any fancy techniques in web development and Blockchain stuff. Don't expect too much from his works. He also forgot to make this site responsive, not alone the UX in connecting Wallet.",
    "Edrik, our marketing dude."]
}

const MoonSenatorTeam = () => {
    return (
        <div id="MoonSenatorTeam">
            <div class={moonSenatorTeam}>
                <h1>{TeamStory.title}</h1>
                <p>{TeamStory.about_team}</p>
            </div>

            <div className={aboutBubble}>
                    {TeamStory.member.map((member,index) => {
                        return(
                            <div class={textBubble}>
                                <h2>{TeamStory.member[index]}</h2>
                                <img src={TeamStory.member_pic[index]} alt="Ginger icon" width="250" height="250"/>
                                <p>{TeamStory.member_bio[index]}</p>
                            </div>
                        )
                    })} 
            </div>
        </div>
    )
}

export default MoonSenatorTeam