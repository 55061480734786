import * as React from 'react'
import { whatBanner,whatContent,nftMarquee,pageStyles,delayed,runFirst} from '../components/layout.module.css'
import Pic1 from "../components/1935.png";
import Pic2 from "../components/2273.png";
import Pic3 from "../components/6561.png";
import Pic4 from "../components/351.png";
import Pic5 from "../components/720.png";
import Pic6 from "../components/820.png";
import Pic7 from "../components/877.png";
import Pic8 from "../components/1259.png";
import Pic9 from "../components/2118.png";

const TopBanner = {
    // color: "#8A6534",
    padding: 4,
    // backgroundColor: "#FFF4DB",
    fontSize: "4rem",
    borderRadius: 4,
    "text-align": "center",
  }

const WhatList = {
    what: "What do MoonSenators mean?",
    whatBanner: "Politically Incorrect.",
    whatContent: ["MoonSenators, from our original idea, are politically incorrect NFT. They are the symbol of naughty and lovely. They treat every human equal, no matter white, black, brown, blue, red, grey, green or yellow skin.",
    "Not just Black Live Matter, but also all lives matter. Female and male are equal. I am kind of disgusted by scenarios that people feel angry when male sexually harassed female but feel funny when female sexually harassed male.",]
}

const MoonSenatorWhat = () => {
    return (
        <div id="MoonSenatorWhat">
            {/* <h1 style={TopBanner}><span></span></h1> */}
            <div className={pageStyles}>
                <h2>{WhatList.what}</h2>
                <h1 class={whatBanner}>{WhatList.whatBanner}</h1>
                {WhatList.whatContent.map(line => (
                    <p class={whatContent}>{line} <br /></p>
                ))}
            </div>
            <div className={nftMarquee}>
                <img className={runFirst} src={Pic1} alt="The moon senator icon Pic1" width="250" height="250"/>
                <img className={runFirst} src={Pic2} alt="The moon senator icon Pic2" width="250" height="250"/>
                <img className={runFirst} src={Pic3} alt="The moon senator icon Pic3" width="250" height="250"/>
                <img className={runFirst} src={Pic4} alt="The moon senator icon Pic4" width="250" height="250"/>
                <img className={runFirst} src={Pic5} alt="The moon senator icon Pic5" width="250" height="250"/>
                <img className={runFirst} src={Pic6} alt="The moon senator icon Pic6" width="250" height="250"/>
                <img className={runFirst} src={Pic7} alt="The moon senator icon Pic7" width="250" height="250"/>
                <img className={runFirst} src={Pic8} alt="The moon senator icon Pic8" width="250" height="250"/>
                <img className={runFirst} src={Pic9} alt="The moon senator icon Pic9" width="250" height="250"/>
                <img className={delayed} src={Pic1} alt="The moon senator icon Pic1" width="250" height="250"/>
                <img className={delayed} src={Pic2} alt="The moon senator icon Pic2" width="250" height="250"/>
                <img className={delayed} src={Pic3} alt="The moon senator icon Pic3" width="250" height="250"/>
                <img className={delayed} src={Pic4} alt="The moon senator icon Pic4" width="250" height="250"/>
                <img className={delayed} src={Pic5} alt="The moon senator icon Pic5" width="250" height="250"/>
                <img className={delayed} src={Pic6} alt="The moon senator icon Pic6" width="250" height="250"/>
                <img className={delayed} src={Pic7} alt="The moon senator icon Pic7" width="250" height="250"/>
                <img className={delayed} src={Pic8} alt="The moon senator icon Pic8" width="250" height="250"/>
                <img className={delayed} src={Pic9} alt="The moon senator icon Pic9" width="250" height="250"/>
            </div>
        </div>
    )
}

export default MoonSenatorWhat