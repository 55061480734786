import * as React from 'react'
import { walletBox, metaMaskSVG,metaMaskSVGGrayIcon,enableEthereumButton,showAccount,clickMeMsg,tokenIDInputStyle,tokenIDh2Style,notConnectedBanner } from '../components/layout.module.css'
import MetaMaskIcon from "../images/metamask-fox.svg";
import { ethers, utils } from 'ethers'
import mintButtonPng from "../components/mint_button_background.png";
import burnButtonPng from "../components/burn_button.png";
import { useState } from 'react';

let contractABI = require('./abi.json');
// let contractAddress = "0xe450080d6da153ec20bfada40a95789c3fec0037";
// let PUBLIC_KEY= "0xF1dcC0300704cB50a7D1d03e18B0D5794c6A03b6"; //gin's add
// let tokenURI = "ipfs://QmVZHCirQ3E8fibasnGLXTCAeF9uHBAnqkDme2onmqgZUD";

// const avaxcontractAddress = '0x02f9045764b5b8ebB6B7B9D0048Fcee6327b1665' //testnet
const avaxcontractAddress = '0x73a917AB4B9ABF4EF4E2BCd34f75BaE689561fa4' //AVAX C-Chain

let metaMaskSVGClass = metaMaskSVGGrayIcon;
let remainingToken = "?"

async function onMintPressed(){
    const {status} = await ethereumAVAXMint();
    console.log(status);
};

async function onBurnPressed(){
  const {status} = await ethereumAVAXBurnForMint();
  console.log(status);
};

async function ethereumAVAXMint(){
  try{
    let nft 
    let signer
    let provider
    if (window.ethereum) {
      await window.ethereum.enable(); // Enable the Ethereum client
      provider = await new ethers.providers.Web3Provider(window.ethereum); // A connection to the Ethereum network
      signer = provider.getSigner(); // Holds your private key and can sign things

      nft = new ethers.Contract( // We will use this to interact with the NFT contract
        avaxcontractAddress,
        contractABI,
        signer
      );
    } else {
      alert("No wallet detected");
    }

      // hash is the hash of the transaction
      let hash = await nft.mint("1",{
        // Calling the getItem function of the contract
        value: ethers.utils.parseEther("0.38"), // 0.5 AVAX
        // gasPrice:25000000000,
        gasLimit:8000000,
      });
      console.log("Transaction sent! Hash:", hash);
      // await provider.waitForTransaction(hash); // Wait till the transaction is mined
      // console.log("Transaction mined!");
      // alert(`Transaction sent! Hash: ${hash}`);

        return {
            success: true,
            status: "✅ Check out your transaction on Snowtrace: https://testnet.snowtrace.io/tx/" + hash.hash
        }
        // if (onSuccess && typeof onSuccess === 'function') onSuccess(tx)
        

  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message
  }
  // if (onError && typeof onError === 'function') onError(error)
  }

}

async function ethereumAVAXBurnForMint(){
  try{

    const tokenIDInput = document.querySelector('#tokenIDInput');
    console.log("Token ID to be burnt:" + tokenIDInput.value);

    if(tokenIDInput.value === "" || tokenIDInput.value == null){
      alert("Please input a token ID");
      return {        
        success: false,
        status: "😥 Something went wrong: No Token ID inputed."
      }
    }

    let nft 
    let signer
    let provider
    if (window.ethereum) {
      await window.ethereum.enable(); // Enable the Ethereum client
      provider = await new ethers.providers.Web3Provider(window.ethereum); // A connection to the Ethereum network
      signer = provider.getSigner(); // Holds your private key and can sign things
      nft = new ethers.Contract( // We will use this to interact with the NFT contract
        avaxcontractAddress,
        contractABI,
        signer
      );
    } else {
      alert("No wallet detected");
    }
    // hash is the hash of the transaction
    let hash = await nft.burnForMint(tokenIDInput.value,{
      // Calling the getItem function of the contract
      value: ethers.utils.parseEther("0.05"), // 0.5 AVAX
      // gasPrice:25000000000,
      gasLimit:8000000,
    });
    console.log("Transaction sent! Hash:", hash);
    return {
        success: true,
        status: "✅ Check out your transaction on Snowtrace: https://testnet.snowtrace.io/tx/" + hash.hash
    }
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message
    }
  }
}

async function getMintedNumber(setRemain){
  let nft 
    let signer
    let provider
    if (window.ethereum) {
      await window.ethereum.enable(); // Enable the Ethereum client
      provider = await new ethers.providers.Web3Provider(window.ethereum); // A connection to the Ethereum network
      signer = provider.getSigner(); // Holds your private key and can sign things

      nft = new ethers.Contract( // We will use this to interact with the NFT contract
        avaxcontractAddress,
        contractABI,
        signer
      );
    } else {
      alert("No wallet detected");
    }

    let hash = await nft.totalSupply();
    let maxHash = await nft.maxSupply();
    // console.log("Total Supply Hash:" + hash);
    if(hash){
      
      let supplied = parseInt(hash._hex, 16);
      let maxSupply = parseInt(maxHash._hex, 16);
      console.log("Total Supply Hash:" + supplied + " max :" + maxSupply);
      remainingToken = maxSupply-supplied;
      setRemain(remainingToken)
    }
}

const remainingTokenSpan = ({remainingToken}) => {
  return <span>{remainingToken}</span>
}

const MetaMaskBox = () => {
  const [remain, setRemain] = useState(remainingToken)
  const [networkVersion, setnetworkVersion] = useState()
   
    React.useEffect(() => {
        let ethereum = window.ethereum;
        metaMaskSVGClass = ethereum ? metaMaskSVG : metaMaskSVGGrayIcon;
        const ethereumButton = document.querySelector('#mintButton');
        const ethereumBurnButton = document.querySelector('#burnButton');
        const alertBanner = document.querySelector('#alertBanner');
        const supportedNetworkIds = [43114];
        const testNetworkId = [43113];
        // const statusText = document.querySelector('#statusText');
        // const showStatus = document.querySelector('#status');
        let currentAccount = "";

        function isSupportedNetworkID(networkId){
          return supportedNetworkIds.includes(parseInt(networkId));
        }

        function isTestNetworkID(networkId){
          return testNetworkId.includes(parseInt(networkId));
        }

        async function refresh(){
          setTimeout(function(){
              //do what you need here
              setnetworkVersion({});
              initMetaMask();
          }, 2000);
          
        }


        function handleAccountsChanged(accounts) {
            console.log('Calling HandleChanged')
            
            if (accounts.length === 0) {
                console.log('Please connect to MetaMask.');
                // statusText.innerHTML = ('Connect with Metamask')
            } else if (accounts[0] !== currentAccount) {
                currentAccount = accounts[0];
                // statusText.innerHTML = (currentAccount)
                // $('#status').html('')
                
                if(currentAccount != null) {
                    // Set the button label
                    // statusText.innerHTML = (currentAccount)
                }
            }
            console.log('WalletAddress in HandleAccountChanged ='+currentAccount)
        }

        function connect() {
          try{
            console.log('Calling connect()')
            ethereum
            .request({ method: 'eth_requestAccounts' })
            // .then(handleAccountsChanged)
            .catch((err) => {
            if (err.code === 4001) {
                // EIP-1193 userRejectedRequest error
                // If this happens, the user rejected the connection request.
                console.log('Please connect to MetaMask.');
                // showStatus.innerHTML('You refused to connect Metamask')
            } else {
                console.error(err);
            }
            });
          } catch (error) {
            console.log("Ethereum not detected in this browser.");
            alertBanner.innerHTML = "Ethereum not detected in this browser. Please install Metamask Wallet.";
          }
        }

        function initMetaMask(){
          if (typeof ethereum == 'undefined'){
            alertBanner.innerHTML = "It seems MetaMask is not connected, press this banner to connect.";
            alertBanner.classList.add(notConnectedBanner);
            alertBanner.addEventListener('click', () => {
              connect();
            });
          }else if(ethereum.networkVersion == null|| !ethereum.isConnected()){
            // this.setState({});
            refresh();
            console.log("Reloading to connect ethereum.")
          }else if(ethereum && isSupportedNetworkID(ethereum.networkVersion)){
            console.log("installed");
            ethereumButton.addEventListener('click', () => {
              onMintPressed();
            });

            ethereumBurnButton.addEventListener('click', () => {
              onBurnPressed();
            });

            // getMintedNumber();
            getMintedNumber(setRemain);
          }else if(ethereum && isTestNetworkID(ethereum.networkVersion)){
            alertBanner.innerHTML = "You are in AVAX FUJI C-Chain now, Please Connect to AVAX Mainnet and refresh this page.";
            alertBanner.classList.add(notConnectedBanner);
          }else{
            alertBanner.innerHTML = "If you want to mint, Please Connect to AVAX C-Chain and refresh this page.";
            alertBanner.classList.add(notConnectedBanner);
            // alert("Please Connect to AVAX C-Chain")
            // statusText.innerHTML = "MetaMask Wallet Not Available";
          }
        }
        
        setTimeout(function(){
            //do what you need here
            initMetaMask();
        }, 1000);

      
  
    }, []);
   
    
  
    return (
      <div className={walletBox}>
        
        <img id="mintButton" src={mintButtonPng} alt="The mint button" className={enableEthereumButton} height="50" width="80"/>
        <img id="burnButton" src={burnButtonPng} alt="The burn button" className={enableEthereumButton} height="50" width="80"/>
        
        {/* <h2 className={showAccount}>
          <MetaMaskIcon className={metaMaskSVGClass}/>
          <span id="statusText">
            Press the button to connect your MetaMask Wallet
          </span>
          <span id="status"></span>
        </h2> */}
        <div className={clickMeMsg}>
          <h2>Remaining Token:<remainingTokenSpan>{remain}</remainingTokenSpan></h2>
        </div>
        <div className={tokenIDh2Style}>
          <h2>Token ID to be Burnt<input id="tokenIDInput" className={tokenIDInputStyle} type="number" min="1" max="10000" /></h2>
          
        </div>
      </div>
    )
  }


  export default MetaMaskBox