import React , { useImperativeHandle, useRef } from "react"
import {AnchorLink} from "gatsby-plugin-anchor-links"
import {NavMenuItem,menu,menuOpen} from '../components/layout.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars} from '@fortawesome/free-solid-svg-icons'


class Menu extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        open: false,
      }
    }
  
    render() {
      return (
        <div
          role="button"
          tabIndex="0"
          className={`${menu} ${this.state.open ? `${menuOpen}` : ""}`}
          onClick={() => this.close()}
          onKeyDown={() => this.close()}
        >
            <AnchorLink className={NavMenuItem} to="/#sectionTopBackground" >Home</AnchorLink>
            <AnchorLink className={NavMenuItem} to="/#sectionMint" >Mint</AnchorLink>
            <AnchorLink className={NavMenuItem} to="/#sectionContent">Intro</AnchorLink>
            <AnchorLink className={NavMenuItem} to="/#sectionWhat" >What</AnchorLink>
            <AnchorLink className={NavMenuItem} to="/#sectionRoadMap" >Plans</AnchorLink>
            <AnchorLink className={NavMenuItem} to="/#sectionMechanism" >Rules</AnchorLink>
            <AnchorLink className={NavMenuItem} to="/#sectionTeam" >Team</AnchorLink>
            <AnchorLink className={NavMenuItem} to="/#sectionFAQ" >FAQ</AnchorLink>
        </div>
      )
    }
  
    close() {
      this.setState({ open: false })
    }
  
    open() {
      this.setState({ open: true })
    }
}

export default React.forwardRef((props, ref) => {
    const menuRef = useRef()

    useImperativeHandle(ref, () => ({
        open() {
        menuRef.current.open()
        },
    }))

    return <Menu ref={menuRef} {...props} />
})