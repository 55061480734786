import * as React from "react"
import { Helmet } from "react-helmet"
import {AnchorLink} from "gatsby-plugin-anchor-links"
import MoonSenator from "../components/moon-senator"
import MetaMaskBox from "../components/meta-mask-box"
import MoonSenatorFAQ from "../components/moon-senator-faq"
import MoonSenatorTeam from "../components/moon-senator-team"
import MoonSenatorWhat from "../components/moon-senator-what"
import MoonSenatorRoadMap from "../components/moon-senator-roadmap"
import MoonSenatorMechanism from "../components/moon-senator-mechanism"
import NavBar from "../components/nav-bar"
import SectionBox from "../components/section-box"
import { pageStyles,NavigationButton,footerIcon,footerCopyRight,footerIconDiv} from '../components/layout.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter,faDiscord} from '@fortawesome/free-brands-svg-icons'
import KalaoIcon from "../components/kalao-favicon.png";

const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Moon Senators</title>
      </Helmet>
      <SectionBox sectionName="sectionNavBar">
        {/* <AnchorLink className={NavigationButton} to="/#MoonSenatorContent" >About</AnchorLink>
        <AnchorLink className={NavigationButton} to="/#MoonSenatorTeam" >Our Team</AnchorLink> */}
        {/* <AnchorLink className={NavigationButton} to="/#sectionTopBackground" >Home</AnchorLink>
        <AnchorLink className={NavigationButton} to="/#sectionMint" >Mint</AnchorLink>
        <AnchorLink className={NavigationButton} to="/#sectionContent">Intro</AnchorLink>
        <AnchorLink className={NavigationButton} to="/#sectionWhat" >What</AnchorLink>
        <AnchorLink className={NavigationButton} to="/#sectionRoadMap" >RoadMap</AnchorLink>
        <AnchorLink className={NavigationButton} to="/#sectionMechanism" >Rules</AnchorLink>
        <AnchorLink className={NavigationButton} to="/#sectionTeam" >Team</AnchorLink>
        <AnchorLink className={NavigationButton} to="/#sectionFAQ" >FAQ</AnchorLink> */}
        <NavBar />
      </SectionBox>
      <SectionBox id="sectionTopBackground" sectionName="sectionTopBackground">

      </SectionBox>
      <SectionBox id="sectionMint" sectionName="sectionMint">
        <MetaMaskBox />     
      </SectionBox>
      <SectionBox id="sectionContent" sectionName="sectionContent">
        <MoonSenator className={pageStyles} />
      </SectionBox>
      <SectionBox id="sectionWhat" sectionName="sectionWhat">
        <MoonSenatorWhat/>
      </SectionBox>
      <SectionBox id="sectionRoadMap" sectionName="sectionRoadMap">
        <MoonSenatorRoadMap className={pageStyles}/>
      </SectionBox>
      <SectionBox id="sectionMechanism" sectionName="sectionMechanism">
        <MoonSenatorMechanism/>
      </SectionBox>
      <SectionBox id="sectionTeam" sectionName="sectionTeam">
        <MoonSenatorTeam className={pageStyles}/>
      </SectionBox>
      <SectionBox id="sectionFAQ" sectionName="sectionFAQ">
        <MoonSenatorFAQ className={pageStyles}/>
      </SectionBox>
      <SectionBox sectionName="sectionFooter">
        <footer id="footer" className={footerCopyRight}>© 2022, moonSenatorNFT</footer>
        <div className={footerIconDiv}>
          <a className={footerIcon} href="https://marketplace.kalao.io/collection/0x73a917ab4b9abf4ef4e2bcd34f75bae689561fa4">
            <img src={KalaoIcon} alt="kalao icon" height="40px" width="40px"></img>
          </a>
          <a className={footerIcon} href="https://discord.gg/MN5ZVUZnB2">
            <FontAwesomeIcon icon={faDiscord} size="2x" />
          </a>
          <a className={footerIcon} href="https://twitter.com/MoonSenators">
            <FontAwesomeIcon icon={faTwitter} size="2x" />
          </a>
        </div>
      </SectionBox>
      <div id="alertBanner"></div>
    </main>
  )
}

export default IndexPage
