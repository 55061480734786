import * as React from "react"
import {AnchorLink} from "gatsby-plugin-anchor-links"
import { NavigationButton,NavBarTitle,TopNavWrap,NavBarIcon} from '../components/layout.module.css'
import Menu from "../components/nav-bar-menu"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars} from '@fortawesome/free-solid-svg-icons'



const NavBar = () => {

    let childMenu;

    function toggleMenu() {
        childMenu.open();
    }

    React.useEffect(() => {
        // const navIconButton = document.querySelector('#myTopnav');
        // navIconButton.addEventListener('click', () => {
        //     navIconFunction();
        // });
    }, []);

    return (
        <div id="myTopnav" className={TopNavWrap}>
            <h1 className={NavBarTitle}>Moon Senator</h1>
            <AnchorLink className={NavigationButton} to="/#sectionTopBackground" >Home</AnchorLink>
            <AnchorLink className={NavigationButton} to="/#sectionMint" >Mint</AnchorLink>
            <AnchorLink className={NavigationButton} to="/#sectionContent">Intro</AnchorLink>
            <AnchorLink className={NavigationButton} to="/#sectionWhat" >What</AnchorLink>
            <AnchorLink className={NavigationButton} to="/#sectionRoadMap" >Plans</AnchorLink>
            <AnchorLink className={NavigationButton} to="/#sectionMechanism" >Rules</AnchorLink>
            <AnchorLink className={NavigationButton} to="/#sectionTeam" >Team</AnchorLink>
            <AnchorLink className={NavigationButton} to="/#sectionFAQ" >FAQ</AnchorLink>
            <a href="javascript:void(0);" className={NavBarIcon} onClick={() => toggleMenu()}>
                <FontAwesomeIcon icon={faBars} size="2x" />
            </a>
            <Menu ref={el => (childMenu = el)} />
            
        </div>

    )
}

export default NavBar